// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.
<template>
  <div class="form-layout" v-ctrl-enter="handleSubmit">
    <a-spin :spinning="loading">
      <a-form
        :ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        @finish="handleSubmit">
        <span>{{ detachVgpuMessage }}</span>
      </a-form>
      <div :span="24" class="action-button">
        <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
        <a-button :loading="loading" type="primary" @click="handleSubmit" ref="submit">{{ $t('label.ok') }}</a-button>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { ref, reactive } from 'vue'
import api2 from '@/wpApi/api2'
import wpapi from '@/wpApi/myApi'
import { api } from '@/api'

export default {
  name: 'DelocateVGPU',
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      detachVgpuMessage: '',
      existingTags: [],
      subAccountUuid: '',
      gpuLicenseKey: 0,
      accountUuid: this.$store.getters.userInfo.accountid,
      accountName: this.$store.getters.userInfo.account,
      getVgpuInfoResponse: '',
      accountUuidAsAdminLogin: ''
    }
  },
  async created () {
    this.initForm()
  },
  mounted () {
    this.fetchVgpu()
    this.fetchData()
    this.getAllTagsApi()
    this.fetchResellerCustomerAccounts()
    this.getAccountUuidIfAdminLogin()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({})
      this.rules = reactive({})
    },
    fetchData () {
      const vmName = this.resource.name
      this.detachVgpuMessage = this.$t('label.vgpu.remove.message', [vmName])
    },
    fetchResellerCustomerAccounts () {
      if (this.resource.account && this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.$route.path.startsWith('/vm/')) {
        if (this.resource.account !== this.accountName) {
          wpapi.getResellerSubAccounts(this.accountUuid).then(response => {
            this.subAccountUuid = response.data.filter(x => x.accountName === this.resource.account)[0].uuid
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    fetchVgpu () {
      var vgpuInput = new FormData()
      vgpuInput.append('vmuuid', this.resource.id)
      api2.getAllocatedGpu(vgpuInput).then(response => {
        this.getVgpuInfoResponse = response.data
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: 'Error fetching GPU State of this VM'
        })
      })
    },
    getAllTagsApi () {
      wpapi.getAllTags().then(response => {
        var allTags = response.data
        var tempTransferInput = []
        for (var tag in allTags) {
          const data = {
            key: allTags[tag].id,
            title: allTags[tag].tagText,
            colors: allTags[tag].tagColor,
            types: allTags[tag].tagType
          }
          tempTransferInput.push(data)
        }
        this.existingTags = tempTransferInput
        // filter for tag Ids of GPU licenses
        this.gpuLicenseKey = this.existingTags.filter(tag => tag.title === 'GPU')[0].key
      })
    },
    closeAction () {
      this.$emit('close-action')
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(() => {
        this.loading = true
        if (this.$store.getters.userInfo.roletype === 'Admin') {
          if (this.accountUuidAsAdminLogin === '') {
            this.$notification.error({
              message: this.$t('label.error'),
              description: this.$t('label.error.missingAccountuuid')
            })
            this.loading = false
            return
          }
        }
        var params = new FormData()
        params.append('vmuuid', this.resource.id)
        api2.deallocateGpu(params).then(async response => {
          var licenseParams = new FormData()
          var licenseParams2 = new FormData()
          if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.resource.account !== this.accountName) {
            licenseParams.append('accountUUID', this.subAccountUuid)
            licenseParams2.append('accountUUID', this.subAccountUuid)
          } else if (this.$store.getters.userInfo.roletype === 'Admin') {
            licenseParams.append('accountUUID', this.accountUuidAsAdminLogin)
            licenseParams2.append('accountUUID', this.accountUuidAsAdminLogin)
          } else {
            licenseParams.append('accountUUID', this.accountUuid)
            licenseParams2.append('accountUUID', this.accountUuid)
          }
          licenseParams.append('tagId', this.gpuLicenseKey)
          if (this.getVgpuInfoResponse !== false && this.getVgpuInfoResponse !== '') {
            licenseParams2.append('tagId', this.getVgpuInfoResponse.licensetagid)
          }
          licenseParams.append('vmUUID', this.resource.id)
          licenseParams2.append('vmUUID', this.resource.id)
          await wpapi.deleteVmTagFromVmDb(licenseParams).then(response => {
          }).catch(error => {
            this.$notifyError(error)
          })
          await wpapi.deleteVmTagFromVmDb(licenseParams2).then(response => {
          }).catch(error => {
            this.$notifyError(error)
          })
          this.$notification.success({
            message: this.$t('message.vpgu.detach.success')
          })
          this.loading = false
          this.closeAction()
          this.$emit('refresh-data')
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: this.$t('message.vpgu.detach.error'),
            description: this.$t('message.vpgu.detach.error2'),
            duration: 0
          })
          this.loading = false
          this.closeAction()
          this.$emit('refresh-data')
        })
      }).catch(error => {
        console.log(error)
        this.loading = false
        this.closeAction()
        this.$emit('refresh-data')
      })
    },
    getAccountUuidIfAdminLogin () {
      if (this.$store.getters.userInfo.roletype === 'Admin') {
        api('listAccounts', {
          listAll: true,
          name: this.resource.account,
          domainid: this.resource.domainid
        }).then(response => {
          if (response.listaccountsresponse.account.length === 1) {
            this.accountUuidAsAdminLogin = response.listaccountsresponse.account[0].id
          }
        }).catch(error => {
          this.$notifyError(error)
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form-layout {
  width: 80vw;
  @media (min-width: 700px) {
    width: 600px;
  }
}

.form {
  margin: 10px 0;
}
</style>
